import request, { IResult } from "../../request";

export enum AppConfigEnum {
    ANIME = "ANIME",
    GAME = "GAME",
}

export interface IAppItemConfig {
    id?: string|number;
    title: string;
    subTitle?: string;
    className?: string;
    image: string;
    link: string;
    content?: string;
}

interface IConfigQuery {
    typeCode: string;
    dictCode?: string;
}

interface IConfigVO {
    typeCode: string;
    typeName: string;
    dictCode: string;
    dictValue: string;
}

export const getConfigList = async (params: IConfigQuery) :Promise<IResult<IConfigVO[]>> => {
    return await request.get<IConfigVO[]>("/wrapper/permit/config/getConfigList", { params });
}

export const testDB = async () :Promise<IResult<IConfigVO[]>> => {
    return await request.get("/wrapper/test/testDB", {});
}

export const getAppConfigList = async (typeCode: AppConfigEnum) => {
    const result = await getConfigList({ typeCode });
    if (result.code === '0') {
        return (result.data || []).map(item  => {
            return JSON.parse(item.dictValue) as IAppItemConfig[];
        }).shift() || [] as IAppItemConfig[];
    }

    return [];
}