import './App.scss';
import React, { useState, useEffect, createContext } from 'react';
import routes from './routes';
import { AppContext, IUserInfo } from './context/AppContext';

import ReactRouter, { Router, Routes, Route, Link, Navigate, useRoutes } from 'react-router-dom';

const App: React.FC = () => {

    const [theme, setTheme] = useState(null!);
    const [config, setConfig] = useState(null!);
    const [userInfo, setUserInfo] = useState<IUserInfo>(null!);

    const content = useRoutes(routes);

    return <AppContext.Provider value={{ config, theme, userInfo }}>
        { content }
    </AppContext.Provider>
};

export default App;