import { createContext } from 'react';


export interface IUserInfo {
    userId: String
    nickname: String
    avatar: String
}

export interface IAppContext {
    theme: Map<String, String>
    config: Map<string, String>
    userInfo: IUserInfo
}

export const AppContext = createContext<IAppContext>(null!);