import './Secret.scss';
import classNames from 'classnames';
import Beian from '../../components/beian/Beian';
import React, { useEffect, useState } from 'react';
import { getConfigList } from '../../api/wrapper/business/configApi';
import { validatePassword } from '../../api/wrapper/home/passwordApi';
import { getAppConfigList, AppConfigEnum, IAppItemConfig }  from '../../api/wrapper/business/configApi';

enum UserStatus {
  LoggedIn = "Logged In",
  LoggingIn = "Logging In",
  LoggedOut = "Logged Out",
  LogInError = "Log In Error",
  VerifyingLogIn = "Verifying Log In"
}

enum WeatherType {
  Cloudy = "Cloudy",
  Rainy = "Rainy",
  Stormy = "Stormy",
  Sunny = "Sunny"
}

interface IPosition {
  left: number;
  x: number;
}

const defaultPosition = (): IPosition => ({
  left: 0,
  x: 0
})

interface INumberUtility {
  clamp: (min: number, value: number, max: number) => number;
  rand: (min: number, max: number) => number;
}

const N: INumberUtility = {
  clamp: (min: number, value: number, max: number) => Math.min(Math.max(min, value), max),
  rand: (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min)
}

interface ITimeUtility {
  format: (date: Date) => string;
  formatHours: (hours: number) => string;
  formatSegment: (segment: number) => string;
}

const T: ITimeUtility = {
  format: (date: Date): string => {
    const hours: string = T.formatHours(date.getHours()),
          minutes: number = date.getMinutes();

    return `${hours}:${T.formatSegment(minutes)}`;
  },
  formatHours: (hours: number): string => {
    return `${hours}`;
    // return hours % 12 === 0 ? '12' : `${hours % 12}`;
  },
  formatSegment: (segment: number): string => {
    return segment < 10 ? `0${segment}` : `${segment}`;
  }
}

interface ILogInUtility {
  verify: (pin: string) => Promise<boolean>;
}

const LogInUtility: ILogInUtility = {
  verify: async (pin: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      const validate = await validatePassword({password: pin});
      setTimeout(() => {
        if(validate && validate.data === true) {
          resolve(true);
        } else {
          reject(`Invalid pin: ${pin}`);
        }
      }, N.rand(300, 700));
    });
  }
}

const useCurrentDateEffect = (): Date => {
  const [date, setDate] = React.useState<Date>(new Date());

  React.useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => {
      const update: Date = new Date();

      if(update.getSeconds() !== date.getSeconds()) {
        setDate(update);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [date]);

  return date;
}

interface IScrollableComponentState {
  grabbing: boolean;
  position: IPosition;
}

interface IScrollableComponentProps {
  children: any;
  className?: string;
  id?: string;
}

const ScrollableComponent: React.FC<IScrollableComponentProps> = (props: IScrollableComponentProps) => {
  const ref: React.RefObject<HTMLDivElement> = React.useRef<HTMLDivElement>(null);

  const [state, setStateTo] = React.useState<IScrollableComponentState>({
    grabbing: false,
    position: defaultPosition()
  });

  const handleOnMouseDown = (e: any): void => {
    setStateTo({
      ...state,
      grabbing: true,
      position: {
        x: e.clientX,
        left: ref?.current?.scrollLeft || 0
      }
    });
  }

  const handleOnMouseMove = (e: any): void => {
    if(state.grabbing) {
      const left: number = Math.max(0, state.position.left + (state.position.x - e.clientX));

      if (ref && ref.current) {
        ref.current.scrollLeft = left;
      }
    }
  }

  const handleOnMouseUp = (): void => {
    if(state.grabbing) {
      setStateTo({ ...state, grabbing: false });
    }
  }

  return (
    <div
      ref={ref}
      className={classNames("scrollable-component", props.className)}
      id={props.id}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      onMouseLeave={handleOnMouseUp}
    >
      {props.children}
    </div>
  );
}

// const WeatherSnap: React.FC = () => {
//   const [temperature] = React.useState<number>(N.rand(65, 85));

//   return(
//     <span className="weather">
//       <i className="weather-type fa-duotone fa-sun"/>
//       <span className="weather-temperature-value">{temperature}</span>
//       <span className="weather-temperature-unit">°F</span>
//     </span>
//   )
// }

// const Reminder: React.FC = () => {
//   return(
//     <div className="reminder">
//       <div className="reminder-icon">
//         <i className="fa-regular fa-bell" />
//       </div>
//       <span className="reminder-text">Extra cool people meeting <span className="reminder-time">10AM</span></span>
//     </div>
//   )
// }

const Time: React.FC = () => {
  const date: Date = useCurrentDateEffect();

  return(
    <span className="time">{T.format(date)}</span>
  )
}

interface IInfoProps {
  id?: string;
}

const Info: React.FC<IInfoProps> = (props: IInfoProps) => {
  return(
    <div id={props.id} className="info">
      <Time />
      {/* <WeatherSnap /> */}
    </div>
  )
}

interface IPinDigitProps {
  focused: boolean;
  value: string;
}

const PinDigit: React.FC<IPinDigitProps> = (props: IPinDigitProps) => {
  const [hidden, setHiddenTo] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(props.value) {
      const timeout: NodeJS.Timeout = setTimeout(() => {
        setHiddenTo(true);
      }, 500);

      return () => {
        setHiddenTo(false);

        clearTimeout(timeout);
      }
    }
  }, [props.value]);

  return (
    <div className={classNames("app-pin-digit", { focused: props.focused, hidden })}>
      <span className="app-pin-digit-value">{props.value || ""}</span>
    </div>
  )
}

const Pin: React.FC = () => {
  const { userStatus, setUserStatusTo } = React.useContext(AppContext);

  const [pin, setPinTo] = React.useState<string>("");

  const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if(userStatus === UserStatus.LoggingIn || userStatus === UserStatus.LogInError) {
      ref?.current?.focus();
    } else {
      setPinTo("");
    }
  }, [userStatus]);

  React.useEffect(() => {
    if(pin.length === 4) {
      const verify = async (): Promise<void> => {
        try {
          setUserStatusTo(UserStatus.VerifyingLogIn);

          if(await LogInUtility.verify(pin)) {
            setUserStatusTo(UserStatus.LoggedIn);
          }
        } catch (err) {
          console.error(err);

          setUserStatusTo(UserStatus.LogInError);
        }
      }

      verify();
    }

    if(userStatus === UserStatus.LogInError) {
      setUserStatusTo(UserStatus.LoggingIn);
    }
  }, [pin, userStatus, setUserStatusTo]);

  const handleOnClick = (): void => {
    ref?.current?.focus();
  }

  const handleOnCancel = (): void => {
    setUserStatusTo(UserStatus.LoggedOut);
  }

  const handleOnChange = (e: any): void => {
    if(e.target.value.length <= 4) {
      setPinTo(e.target.value.toString());
    }
  }

  const getCancelText = (): JSX.Element => {
    return (
      <span id="app-pin-cancel-text" onClick={handleOnCancel}>Cancel</span>
    )
  }

  const getErrorText = (): JSX.Element => {
    if(userStatus === UserStatus.LogInError) {
      return (
        <span id="app-pin-error-text">Invalid</span>
      )
    }

    return <div></div>
  }

  return(
    <div id="app-pin-wrapper">
      <input
        disabled={userStatus !== UserStatus.LoggingIn && userStatus !== UserStatus.LogInError}
        id="app-pin-hidden-input"
        maxLength={4}
        ref={ref}
        type="number"
        value={pin}
        onChange={handleOnChange}
      />
      <div id="app-pin" onClick={handleOnClick}>
        <PinDigit focused={pin.length === 0} value={pin[0]} />
        <PinDigit focused={pin.length === 1} value={pin[1]} />
        <PinDigit focused={pin.length === 2} value={pin[2]} />
        <PinDigit focused={pin.length === 3} value={pin[3]} />
      </div>
      <h3 id="app-pin-label">{getErrorText()} {getCancelText()}</h3>
    </div>
  )
}

interface IMenuSectionProps {
  children: any;
  icon: string;
  id: string;
  scrollable?: boolean;
  title: string;
}

const MenuSection: React.FC<IMenuSectionProps> = (props: IMenuSectionProps) => {
  const getContent = (): JSX.Element => {
    if(props.scrollable) {
      return (
        <ScrollableComponent className="menu-section-content">
          {props.children}
        </ScrollableComponent>
      );
    }

    return (
      <div className="menu-section-content">
        {props.children}
      </div>
    );
  }

  return (
    <div id={props.id} className="menu-section">
      <div className="menu-section-title">
        <i className={props.icon} />
        <span className="menu-section-title-text">{props.title}</span>
      </div>
      {getContent()}
    </div>
  )
}

const QuickNav: React.FC = () => {

  const [navState, setNavState]= React.useState(1);

  const getItems = (): JSX.Element[] => {
    return [{
      id: 1,
      label: "Websites",
      link: '#weather-section'
    }, {
      id: 2,
      label: "Links",
      link: '#restaurants-section'
    }, {
      id: 3,
      label: "Animes",
      link: '#tools-section'
    }, {
      id: 4,
      label: "Games",
      link: '#movies-section'
    }].map((item: any) => {
      return (
        <a key={item.id} className={classNames(["quick-nav-item","clear-button", navState === item.id ? 'nav-active' : ''])}  href={item.link} onClick={() => { getConfigList({typeCode: 'WWW'}).then(data => console.log(data, '====')); setNavState(item.id)}}>
          {item.label}
        </a>
      );
    })
  }

  return (
    <ScrollableComponent id="quick-nav">
      {getItems()}
    </ScrollableComponent>
  );
}


const Tools: React.FC = () => {

  let [list, setList] = useState<IAppItemConfig[]>([]);

  useEffect(() => { getAppConfigList(AppConfigEnum.ANIME).then(data => { setList(data); }); }, []);

  const getTools = (): JSX.Element[] => {
    return list.map((tool) => {

      const styles: React.CSSProperties = {
        backgroundImage: `url(${tool.image})`
      }

      return (
        <div key={tool.id} className="tool-card">
          <div className="tool-card-background background-image" style={styles} />
          <div className="tool-card-content">
            <div className="tool-card-content-header">
              <span className="tool-card-label">{tool.title}</span>
              <span className="tool-card-name">{tool.subTitle}</span>
            </div>
            <i className={classNames(tool.className, "tool-card-icon")} />
          </div>
        </div>
      );
    })
  }

  return (
    <MenuSection icon="fa-solid fa-toolbox" id="tools-section" title="These're all my FAVORITE anime.">
      {getTools()}
    </MenuSection>
  );
}

const Movies: React.FC = () => {

  let [list, setList] = useState<IAppItemConfig[]>([]);
  useEffect(() => { getAppConfigList(AppConfigEnum.GAME).then(data => { setList(data); }); }, []);

  const getMovies = (): JSX.Element[] => {
    return list.map((movie: IAppItemConfig) => {
      const styles: React.CSSProperties = {
        backgroundImage: `url(${movie.image})`
      }

      const id: string = `movie-card-${movie.id}`;

      return (
        <div key={movie.id} id={id} className="movie-card">
          <div className="movie-card-background background-image" style={styles} />
          <div className="movie-card-content">
            <div className="movie-card-info">
              <span className="movie-card-title">{movie.title}</span>
              <span className="movie-card-desc">{movie.content}</span>
            </div>
            <i className={movie.className} />
          </div>
        </div>
      );
    })
  }

  return (
    <MenuSection icon="fa-solid fa-camera-movie" id="movies-section" scrollable title="">
      {getMovies()}
    </MenuSection>
  );
}

interface IUserStatusButton {
  id: string;
  icon: string;
  userStatus: UserStatus;
}

const UserStatusButton: React.FC<IUserStatusButton> = (props: IUserStatusButton) => {
  const { userStatus, setUserStatusTo } = React.useContext(AppContext);

  const handleOnClick = (): void => {
    setUserStatusTo(props.userStatus);
  }

  return(
    <button
      id={props.id}
      className="user-status-button clear-button"
      disabled={userStatus === props.userStatus}
      type="button"
      onClick={handleOnClick}
    >
      <i className={props.icon} />
    </button>
  )
}

const Menu: React.FC = () => {
  return(
    <div id="app-menu">
      <div id="app-menu-content-wrapper">
        <div id="app-menu-content">
          <div id="app-menu-content-header">
            <div className="app-menu-content-header-section">
              <Info id="app-menu-info" />
              {/* <Reminder /> */}
            </div>
            <div className="app-menu-content-header-section">
              <UserStatusButton
                icon="fa-solid fa-arrow-right-from-arc icon"
                id="sign-out-button"
                userStatus={UserStatus.LoggedOut}
              />
            </div>
          </div>
          {/* <QuickNav /> */}
          <Tools />
          <Movies />
        </div>
      </div>
    </div>
  )
}

const Background: React.FC = () => {
  const { userStatus, setUserStatusTo } = React.useContext(AppContext);

  const handleOnClick = (): void => {
    if(userStatus === UserStatus.LoggedOut) {
      setUserStatusTo(UserStatus.LoggingIn);
    }
  }

  return(
    <div id="app-background" onClick={handleOnClick}>
      <div id="app-background-image" className="background-image" />
    </div>
  )
}

const Loading: React.FC = () => {
  return(
    <div id="app-loading-icon">
      <i className="fa-solid fa-spinner-third" />
    </div>
  )
}

interface IAppContext {
  userStatus: UserStatus;
  setUserStatusTo: (status: UserStatus) => void;
}

const AppContext = React.createContext<IAppContext>(null!);

const App: React.FC = () => {
  const [userStatus, setUserStatusTo] = React.useState<UserStatus>(UserStatus.LoggedOut);

  const getStatusClass = (): string => {
    return userStatus.replace(/\s+/g, "-").toLowerCase();
  }

  return(
    <AppContext.Provider value={{ userStatus, setUserStatusTo }}>
      <div id="app" className={getStatusClass()}>
        <Info id="app-info" />
        <Pin />
        <Menu />
        <Background />
        <div id="sign-in-button-wrapper">
          <UserStatusButton
            icon="icon"
            id="sign-in-button"
            userStatus={UserStatus.LoggingIn}
          />
          <a id = "redirect-button-home" className = "clear-button" href = "https://z.fanhehe.com/">
            <i className = "icon"></i>
          </a>
        </div>
        <Beian />
        <Loading />
      </div>
    </AppContext.Provider>
  )
}

export default App;
