import { FC } from "react"
import { Link, NavLink } from "react-router-dom";
import NavList from './nav.json';
import Beian from '../../components/beian/Beian';
import './navigator.scss';

const Navigator: FC = () => {

    const navComponent = NavList.map((item, index) =>
        <div key = {index}>
            <img className="{item.type}" src="{item.icon}"/>
            <NavLink to="{item.link}">{item.name}</NavLink>
        </div>
    )

    return <>
        <nav className = "navigator-container">
            { navComponent }
        </nav>
        <Beian />
    </>
};


export default Navigator;