import Home from "./pages/secret/Secret";
import Main from "./pages/main/main";
import Gallery from "./pages/gallery/gallery";
import Navigator from "./pages/navigator/navigator";
import { RouteObject } from "react-router-dom";

const routes: RouteObject[] = [
  {
    path: "*",
    element: <Home />,
    children: [
      {
        index: true,
        path: "timeline",
        element: <Main/>
      },
      {
        path: "about",
        element: <Main />
      },
      {
        path: "tools",
        element: <Main />
      },
      {
        path: "photos",
        element: <Main />
      }
    ]
  },
  {
    "path": "/home",
    "element": <Home />
  },
  {
    "path": "/navigator",
    "element": <Navigator/>
  },
  {
    "path": "/gallery",
    "element": <Gallery/>
  }
];

export default routes;