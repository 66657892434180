import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IResult<T> {
    code: String;
    message: String;
    data: T;
}

interface IRequest {
  request<T = any, R = IResult<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>;
  get<T = any, R = IResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  delete?<T = any, R = IResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  head?<T = any, R = IResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  options?<T = any, R = IResult<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  post<T = any, R = IResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  put?<T = any, R = IResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patch?<T = any, R = IResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  postForm?<T = any, R = IResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  putForm?<T = any, R = IResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patchForm?<T = any, R = IResult<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
}

const DEFAULT_ERROR :IResult<null> = {
  code: "500",
  message: "请求异常，请稍后再试",
  data: null
}

const { REACT_APP_API_P, REACT_APP_API_URL } = process.env;

// 创建一个 axios 实例, 配置默认值
const _proxy: AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  timeout: 5000
});

function getContentType(response: AxiosResponse): string {
  return response?.headers['content-type'] || response?.headers['Content-Type'] || '';
}

_proxy.interceptors.response.use(
  response => {
    console.log('axios.interceptors.response.data=======>', response.data);
    if (response.status === 200 && /application\/json/gi.test(getContentType(response))) {
      return response.data;
    }
    console.log('axios.interceptors.response.error=======>', response);
    return DEFAULT_ERROR;
  },
  error => {
    console.error('axios.interceptors.response.error========>', error);
    return DEFAULT_ERROR;
  }
);


const request: IRequest = {
  request: config => _proxy.request(config),
  get: (url, config) => _proxy.get(`${REACT_APP_API_P}${url}`, config),
  post: (url, data, config) => _proxy.post(`${REACT_APP_API_P}${url}`, data, config),
}

export default request;
