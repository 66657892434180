import { FC, useState } from "react";
import './main.scss';
import { Routes, Route, NavLink } from "react-router-dom";
import Timeline from "./pages/Timeline";
import Tools from "./pages/Tools";
import Photos from "./pages/Photos";
import About from "./pages/About";
const EMPTY = () => {
    return <>
    </>
}

const menuList = [{src: '11', title: 'fasf'}].map((item, index) => {
    return <div className="menu-item" key={index}>
        <img src="{item.src}"/>
        <span>item.title</span>
    </div>
})

const favoriteList = [].map((item, index) => {
    return <div key={index}>
        <img src="{item.src}" />
        <span>item.title</span>
    </div>
})

const followMe = [].map((item, index) => {
    return <div key={index}>
        <img src="{item.src}" />
        <span>item.title</span>
    </div>
})

const Main: FC = () => {
    return <>
        <main className = "main-container">
            <section className = "main-section main-section-left main-module-color">
                <div className = "main-left-title">Fanhehe</div>
                <nav className = "main-left-menu">
                    <span>Menu</span>
                    { menuList }
                </nav>
                <nav className = "main-left-favorite">
                    <span>Favorite</span>
                    { favoriteList }
                </nav>

                <div className = "main-left-follow">
                    <span>Follow Me</span>
                    { followMe }
                </div>
            </section>
            <section className = "main-section main-section-body">
                <div className = "main-body-search main-module-color">
                    <i></i>
                    <input id = "input" type="text" placeholder="Search"/>
                </div>

                <section className="main-body-header">
                    <div className = "main-body-header-profile">
                        <i></i>
                        <span>Fanhehe</span>
                    </div>
                </section>

                <nav className = "main-body-nav main-module-color">
                    <NavLink className="main-body-nav-item" to={"timeline"}>Timeline</NavLink>
                    <NavLink className="main-body-nav-item" to={"tools"}>Tools</NavLink>
                    <NavLink className="main-body-nav-item" to={"photos"}>Photos</NavLink>
                    <NavLink className="main-body-nav-item" to={"about"}>About</NavLink>
                </nav>

                <section className = "main-body-nav-body">
                    <Routes>
                        <Route path="timeline" element= { <Timeline />}></Route>
                        <Route path="tools"  element = { <Tools/> }></Route>
                        <Route path="photos" element = { <Photos/> }></Route>
                        <Route path="about"  element = { <About/> }></Route>
                    </Routes>
                </section>
            </section>
            <section className = "main-section main-section-right main-module-color">
                <div className="main-right-tools">
                    <div>
                        <a><img src=""></img></a>
                        <a><img src=""></img></a>
                        <span>
                            <span>Fanhehe</span>
                            <a><img src=""></img></a>
                        </span>
                    </div>
                </div>
                <div className = "main-body-stories"></div>
                <div className = "main-body-contacts"></div>
            </section>
        </main>
    </>
}

export default Main;