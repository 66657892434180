import React from 'react';
import './Beian.scss';

const Beian: React.FC =  () => {
    return (<div id = "footer" className='beian'>
        <div className = "container-footer">
            <a href = "https://beian.miit.gov.cn/" target = "_blank" rel="noreferrer">ICP备案/许可证号 浙ICP备2023005168号-1</a>
        </div>
    </div>)
}

export default Beian;